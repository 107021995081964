(function () {
    angular.module('informaApp')
        .component('usageFilter', {
            templateUrl: 'components/admin-sections/usage-section/usage-filter-modal/usage-filter/template.html',
            controller: UsageFilterController,
            bindings: {
                companies: '<',
                onInit: '<'
            }
        });

    function UsageFilterController($scope, ActivityTypes) {
        this.activityTypes = [
            ...getDefaultListOptions(),
            {text: 'Login', value: ActivityTypes.login},
            {text: 'Logout', value: ActivityTypes.logout}
        ];

        this.isSingleDate = true;

        this.activity = {
            activityType: this.activityTypes[0],
            startDate: new Date()
        };

        this.$onChanges = (changes) => {
            if (changes.companies) {
                this.companyList = this.companies
                    ? mapCompanies(this.companies)
                    : getDefaultListOptions();

                this.activity.company = this.companyList[0];
            }

            if (changes.onInit && this.onInit) {
                initialize.call(this);
            }
        };
    }

    function initialize() {
        this.onInit({
            getFilter: () => {
                return {
                    company: this.activity.company.value,
                    activityType: this.activity.activityType.value,
                    startDate: moment(this.activity.startDate).toDate(),
                    endDate: this.isSingleDate ? null : moment(this.activity.endDate).toDate()
                };
            }
        });
    }

    function mapCompanies(companies) {
        return  [
            ...getDefaultListOptions(),
            ...companies.data.data.map(x => ({text: x.originalName, value: x.originalName}))
        ];
    }

    function getDefaultListOptions() {
        return [
            {text: 'All', value: null}
        ];
    }
})();
